<template>
    <datetime
        v-bind="$attrs"
        :type="type"
        :format="format"
        use12-hour
        :zone="timeZone"
        :value-zone="timeZone"
        :min-datetime="nowString"
        input-class="form__input calendar-input"
        v-on="$listeners"
    >
        <template #after>
            <span
                v-if="hasInput"
                class="z-10 h-full absolute items-center justify-center w-6 right-0 pr-3 py-2 cursor-pointer"
                @click.stop="onClear"
            >
                <r-icon name="times" icon-style="fas" color="#aaa" />
            </span>
        </template>
    </datetime>
</template>

<script>
import { Datetime } from 'vue-datetime';
import { includes, isEmpty } from '@/utils/lodash';
import { format } from '@/utils/dates';
export default {
    components: {
        Datetime,
    },
    props: {
        type: {
            type: String,
            default: 'date',
            validator: (value) => {
                return includes(['date', 'time', 'datetime'], value);
            },
        },
        format: {
            type: String,
            default: 'M/dd/yyyy',
        },
    },
    data: () => ({
        nowString: format(new Date()),
    }),
    computed: {
        timeZone() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        },
        hasInput() {
            return !isEmpty(this.$attrs.value);
        },
    },
    methods: {
        update(value) {
            this.$emit('input', value);
        },
        onClear() {
            this.$emit('clear');
        },
    },
};
</script>
<style>
.vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected > span > span:hover,
.vdatetime-popup__header {
    @apply bg-black;
}
.vdatetime-time-picker__item--selected,
.vdatetime-popup__actions__button {
    @apply text-black;
}
</style>
